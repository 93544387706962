export const TITLE_SECTION_STYLES = {
  fontWeight: "normal",
  fontSize: "48px",
  lineHeight: "1.6em",
  textAlign: "center",
  color: "secondaryColorText",
  textTransform: "capitalize",
  fontFamily: "heading"
};

export const HEADING_STYLES = {
  color: 'alternativeColorText',
  fontSize:"24px",
  lineHeight: "24px",
  textTransform: "capitalize",
  fontStyle:'italic',
  fontWeight: "normal",
};

export const CONTENT_STYLES = {
  fontWeight: "normal",
  fontSize: "16px",
  color: "mainColorText",
};